<script>
import { useDebouncedAutoSavedInputFieldRef } from '@/infrastructure/userInput/debounce'
import LabelAndSaveIndicator from '@/features/items/components/fields/LabelAndSaveIndicator'

export default {
  components: { LabelAndSaveIndicator },
  props: {
    value: { type: Number },
    isEditMode: { type: Boolean, required: true },
    save: { type: Function, required: true },
  },

  setup(props) {
    const {
      val: newValue,
      isSaving,
      isShortlyAfterSaved,
    } = useDebouncedAutoSavedInputFieldRef({
      initialValue: props.value,
      saveFunc: (val) => props.save({ newPrice: val }),
    })

    return {
      newValue,
      isSaving,
      isShortlyAfterSaved,
    }
  },

  watch: {
    isEditMode(to) {
      if (to) {
        this.newValue = this.value
      }
    },
  },
}
</script>

<template>
  <div v-if="!isEditMode" class="item-price-text">R {{ value }}</div>

  <div v-else>
    <v-text-field v-model.number="newValue" hide-details type="number" filled>
      <template v-slot:label>
        <LabelAndSaveIndicator label="Price" :is-saving="isSaving" :is-shortly-after-saved="isShortlyAfterSaved" />
      </template>
    </v-text-field>
  </div>
</template>

<style scoped>
.item-price-text {
  white-space: nowrap;
}
</style>
