import { ref } from '@vue/composition-api'
import store from '@/infrastructure/store'
import imageFilesApi from '@/features/images/api/imageFilesApi'

export default function useApiImageUploading() {
  const isBusyUploading = ref(false)
  const progressPercent = ref(0)
  const error = ref(undefined)
  const imageThatHadError = ref(undefined)

  const nonFileApiPercentage = 10 // used to give perception of progress while making multiple api calls and file upload having its own progress
  const numberOfNonFileApiCalls = 2 // the number of apis before/after and excluding the file upload one
  const fileUploadMaxPercentage = 100 - nonFileApiPercentage * numberOfNonFileApiCalls

  const uploadAndConfirmImage = async ({ image }) => {
    progressPercent.value = 0

    const uploadPlaceholder = await imageFilesApi.generateImageUploadPlaceholder({ friendlyName: image.name })
    const imageId = uploadPlaceholder.imageId
    const signedUrl = uploadPlaceholder.signedUrl
    // console.debug({ imageId, signedUrl, image })

    progressPercent.value = nonFileApiPercentage // first api call is done

    await imageFilesApi.uploadImageToSignedUrl({
      signedUrl: signedUrl,
      file: image.file,
      onUploadProgress: (progressEvent) => {
        const fileUploadPercentage = Math.floor((progressEvent.loaded * 100) / progressEvent.total)

        progressPercent.value = nonFileApiPercentage + (fileUploadPercentage * fileUploadMaxPercentage) / 100
        // console.debug('onUploadProgress, uploadPercent: ', progressPercent.value)
      },
    })

    await imageFilesApi.confirmImageUploaded({ imageFileId: imageId })

    progressPercent.value = 100 // last api call is done

    return { imageId }
  }

  const executeUpload = async (image) => {
    if (!image) return

    isBusyUploading.value = true
    progressPercent.value = 0
    error.value = undefined
    imageThatHadError.value = undefined

    try {
      const { imageId } = await uploadAndConfirmImage({ image: image })

      if (image.clearValue) {
        image.clearValue()
      }

      isBusyUploading.value = false

      return { imageId }
    } catch (err) {
      console.error('error uploading, clearing image', err)
      store.commit('addError', 'Failed to upload image')
      isBusyUploading.value = false
      error.value = String(err)
      imageThatHadError.value = image
    }
  }

  const clearImageAndError = () => {
    if (imageThatHadError.value && imageThatHadError.value.clearValue) imageThatHadError.value.clearValue()
    error.value = undefined
  }

  return {
    isBusyUploading,
    progressPercent,
    error,
    imageThatHadError,

    executeUpload,
    clearImageAndError,
  }
}
