import axios from 'axios'
import { createApiInstance } from '@/infrastructure/apis/apiInstance'

/**
 * @typedef {Object} ImagePlaceholder
 * @property {string} imageId
 * @property {string} signedUrl
 */

export default {
  /**
   * @param {string} friendlyName
   * @returns {Promise<ImagePlaceholder>}
   */
  generateImageUploadPlaceholder: async ({ friendlyName }) => {
    const response = await createApiInstance().post('/ImageFiles/image-upload-placeholder', { friendlyName: friendlyName })
    return response.data
  },

  /**
   * @param {string} signedUrl
   * @param {File} file
   * @param {(progressEvent: any) => void} onUploadProgress
   * @returns {Promise<ImagePlaceholder>}
   */
  uploadImageToSignedUrl: async ({ signedUrl, file, onUploadProgress }) => {
    let imageFileType = file.type
    if (!imageFileType) {
      console.warn('Unable to determine image type from file object, using default', { file })
      imageFileType = 'image/png'
    }

    await axios.put(signedUrl, file, {
      timeout: 15 * 60 * 1000,
      headers: {
        'x-ms-blob-type': `BlockBlob`,
        'Content-Type': imageFileType,
      },
      onUploadProgress: onUploadProgress,
    })
  },

  /**
   * @param {string} imageFileId
   * @returns {Promise<ImagePlaceholder>}
   */
  confirmImageUploaded: async ({ imageFileId }) => {
    const response = await createApiInstance().put(`/ImageFiles/confirm-upload/${imageFileId}`)
    return response.data
  },
}
