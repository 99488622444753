<script>
import { useDebouncedAutoSavedInputFieldRef } from '@/infrastructure/userInput/debounce'
import { useItemLinkDisplayFormatting } from '@/features/items/itemHelpers'
import { toRefs } from '@vue/composition-api'
import LinksManager from '@/features/items/components/LinksManager'

export default {
  components: { LinksManager },

  props: {
    value: { type: Array, required: true },
    isEditMode: { type: Boolean, required: true },
    save: { type: Function, required: true },
  },

  setup(props) {
    const propsRef = toRefs(props).value
    const { formattedLinks } = useItemLinkDisplayFormatting(propsRef)

    const {
      val: newValue,
      isSaving,
      isShortlyAfterSaved,
    } = useDebouncedAutoSavedInputFieldRef({
      initialValue: props.value,
      saveFunc: (val) => props.save({ newLinks: val }),
    })

    return {
      formattedLinks,

      newValue,
      isSaving,
      isShortlyAfterSaved,
    }
  },

  watch: {
    isEditMode(to) {
      if (to) {
        this.newValue = this.value
      }
    },
  },
}
</script>

<template>
  <div v-if="!isEditMode" class="d-flex align-center flex-wrap mt-3 item-links">
    <div v-for="(formattedLink, i) in formattedLinks" :key="i">
      <span v-if="i > 0" class="mx-2 secondary--text text--lighten-4">•</span>
      <a :href="formattedLink.url" target="_blank" class="blue-grey--text" style="text-decoration: none">{{ formattedLink.text }} </a>
    </div>
  </div>

  <LinksManager v-else v-model="newValue" :is-saving="isSaving" :is-shortly-after-saved="isShortlyAfterSaved" />
</template>

<style scoped>
.item-links {
  word-break: break-word;
}
</style>
