import { computed } from '@vue/composition-api'

export function useItemLinkDisplayFormatting(linksRef) {
  const formattedLinks = computed(() =>
    linksRef.value.map((url) => {
      try {
        const tmpUrlObj = new URL(url)

        return {
          text: tmpUrlObj.hostname,
          url: url,
        }
      } catch (e) {
        return {
          text: url,
          url: url,
        }
      }
    })
  )

  return {
    formattedLinks,
  }
}
