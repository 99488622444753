export function extractImageAndDetailsFromFileInputEvent(e) {
  const files = e.target.files
  const clearValue = () => (e.target.value = '')

  if (files[0] !== undefined) {
    let imageName = files[0].name
    if (imageName.lastIndexOf('.') <= 0) {
      return undefined
    }

    return new Promise((resolve) => {
      const fr = new FileReader()
      fr.readAsDataURL(files[0])
      fr.addEventListener('load', () => {
        resolve({ name: imageName, url: fr.result, file: files[0], clearValue: clearValue })
      })
    })
  }

  return undefined
}
