<script>
import { useDebouncedAutoSavedInputFieldRef } from '@/infrastructure/userInput/debounce'
import LabelAndSaveIndicator from '@/features/items/components/fields/LabelAndSaveIndicator'

export default {
  components: { LabelAndSaveIndicator },
  props: {
    value: { type: String, required: true },
    isEditMode: { type: Boolean, required: true },
    save: { type: Function, required: true },
  },

  setup(props) {
    const {
      val: newValue,
      isSaving,
      isShortlyAfterSaved,
    } = useDebouncedAutoSavedInputFieldRef({
      initialValue: props.value,
      saveFunc: (val) => props.save({ newName: val }),
    })

    return {
      newValue,
      isSaving,
      isShortlyAfterSaved,
    }
  },

  data() {
    return {
      rules: {
        nameRequired: (value) => !!value || 'Name is required',
      },
    }
  },

  watch: {
    isEditMode(to) {
      if (to) {
        this.newValue = this.value
      }
    },
  },
}
</script>

<template>
  <div v-if="!isEditMode" class="item-name-text">{{ value }}</div>

  <div v-else>
    <v-text-field v-model="newValue" :rules="[rules.nameRequired]" filled hide-details>
      <template v-slot:label>
        <LabelAndSaveIndicator label="Name" :is-saving="isSaving" :is-shortly-after-saved="isShortlyAfterSaved" />
      </template>
    </v-text-field>
  </div>
</template>

<style scoped>
.item-name-text {
  word-break: break-word;
}
</style>
