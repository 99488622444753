<script>
import { extractImageAndDetailsFromFileInputEvent } from '@/features/images/services/imageFileHelpers'
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import { computed, ref, toRefs, watch } from '@vue/composition-api'
import store from '@/infrastructure/store'

export default {
  props: {
    readonly: { type: Boolean },
    imageId: { type: String },
    imageWidth: { type: Number, required: true },
    imageUploadPercentage: { type: Number, required: true },
    uploadError: { type: String },
    executeUpload: { type: Function, required: true },
    replaceItemImage: { type: Function, required: true },
    removeItemImage: { type: Function, required: true },
    thumbnailFullUrl: { type: String },
  },

  setup(props) {
    const imageElement = ref(null)

    const imageIdProp = toRefs(props).imageId
    const imageIdToDisplay = ref(props.imageId)

    const imageUploadPercentage = toRefs(props).imageUploadPercentage

    watch(imageIdProp, (newValue, oldValue) => {
      if (!oldValue && newValue) {
        imageIdToDisplay.value = newValue
      }
    })

    const isItemImageSaved = ref(false)
    const totalImageUpdateProgressPercentage = computed(() => ((imageUploadPercentage.value * 90) / 100 + (isItemImageSaved.value ? 10 : 0)).toFixed(0))

    const openFilePicker = () => {
      imageElement.value.click()
    }

    const { isBusy: isUploadBusy, execute: uploadImage } = useAsyncLoading({
      actionFactory: async (image) => {
        isItemImageSaved.value = false

        const { imageId } = await props.executeUpload(image)
        await props.replaceItemImage({ imageId: imageId })

        imageIdToDisplay.value = imageId
        isItemImageSaved.value = true

        imageElement.value.value = ''
      },
    })

    const { isBusy: isDeleteBusy, execute: deleteImage } = useAsyncLoading({
      actionFactory: async () => {
        const imageIdForUndo = imageIdToDisplay.value

        await props.removeItemImage({ imageId: imageIdToDisplay.value })
        imageIdToDisplay.value = undefined

        store.commit('addToast', {
          message: 'Image deleted',
          timeout: 10000,
          actionText: 'Undo',
          actionFunction: async () => {
            imageIdToDisplay.value = imageIdForUndo
            await props.replaceItemImage({ imageId: imageIdForUndo })
          },
        })
      },
    })

    return {
      imageElement,
      openFilePicker,

      imageIdToDisplay,

      isUploadBusy,
      uploadImage,

      isDeleteBusy,
      deleteImage,

      totalImageUpdateProgressPercentage,
    }
  },

  methods: {
    async onFilePicked(e) {
      const imageDetails = await extractImageAndDetailsFromFileInputEvent(e)
      this.uploadImage(imageDetails)
    },
  },
}
</script>

<template>
  <div>
    <div class="d-flex justify-center">
      <v-card :min-width="imageWidth">
        <v-img
          v-if="thumbnailFullUrl"
          :key="thumbnailFullUrl"
          :src="thumbnailFullUrl"
          :width="imageWidth"
          :height="imageWidth"
          contain
          alt="Thumbnail"
          class="mx-auto mt-3"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <div v-else class="d-flex align-center justify-center">
          <v-icon :size="imageWidth * 0.75" color="grey lighten-2" @click="openFilePicker">mdi-camera-plus-outline </v-icon>
        </div>

        <v-card-actions>
          <v-btn v-if="imageIdToDisplay" icon color="error" :disabled="readonly || isDeleteBusy" :loading="isDeleteBusy" @click="deleteImage">
            <v-icon size="20">mdi-trash-can-outline</v-icon>
          </v-btn>

          <v-spacer />

          <v-btn
            :block="!imageIdToDisplay"
            :text="!!imageIdToDisplay"
            color="primary"
            outlined
            :disabled="readonly || isUploadBusy"
            :loading="isUploadBusy"
            @click="openFilePicker"
          >
            <v-icon left class="mr-1">{{ imageIdToDisplay ? 'mdi-camera-retake-outline' : 'mdi-camera-plus-outline' }} </v-icon>
            <div v-if="imageIdToDisplay">Replace</div>
            <div v-else>Add image</div>

            <template v-slot:loader>
              <div class="px-3" style="width: 100%">
                <div class="primary--text">
                  <small>{{ totalImageUpdateProgressPercentage }}%</small>
                </div>
                <v-progress-linear :value="totalImageUpdateProgressPercentage" buffer-value="0" stream rounded color="primary" class="mx-auto" />
              </div>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <input type="file" style="display: none" ref="imageElement" accept="image/*" @change="onFilePicked" />
  </div>
</template>
