<script>
import config from '@/config'
import ItemImageEditor from '@/features/images/components/ItemImageEditor'

export default {
  components: { ItemImageEditor },

  props: {
    value: { type: String },
    itemId: { type: String },
    isMyItem: { type: Boolean },

    isEditMode: { type: Boolean, required: true },

    imageUploadPercentage: { type: Number },
    uploadError: { type: String },
    executeUpload: { type: Function, required: true },

    replaceItemImage: { type: Function, required: true },
    removeItemImage: { type: Function, required: true },

    imageWidth: { type: Number, default: () => 120 },
  },

  data() {
    return {
      hideOnImageError: false,
    }
  },

  computed: {
    thumbnailFullUrl() {
      if (this.value) {
        return `${config.ApiBaseUrl}/ImageFiles/images/${this.value}/thumbnail/${this.imageWidth}/${this.imageWidth}`
      }

      if (this.itemId) {
        if (this.isMyItem) {
          return `${config.ApiBaseUrl}/MyItems/${this.itemId}/main-image`
        } else {
          return `${config.ApiBaseUrl}/FriendItems/items/${this.itemId}/main-image`
        }
      }

      return undefined
    },
  },

  methods: {
    onImageLoadError() {
      console.warn(`Failed to load image thumbnail URL '${this.thumbnailFullUrl}', hiding image element`)
      this.hideOnImageError = true
    },
  },
}
</script>

<template>
  <v-avatar v-if="!isEditMode && thumbnailFullUrl && !hideOnImageError" class="ma-3" :size="imageWidth" tile>
    <v-img v-if="thumbnailFullUrl" :src="thumbnailFullUrl" :width="imageWidth" :height="imageWidth" contain @error="onImageLoadError">
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular indeterminate color="grey lighten-2"></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </v-avatar>

  <ItemImageEditor
    v-else-if="isEditMode"
    :image-id="value"
    :image-width="imageWidth"
    :image-upload-percentage="imageUploadPercentage"
    :upload-error="uploadError"
    :execute-upload="executeUpload"
    :replace-item-image="replaceItemImage"
    :remove-item-image="removeItemImage"
    :thumbnail-full-url="thumbnailFullUrl"
    class="ma-4"
  />
</template>
