<script>
export default {
  props: {
    value: { type: Number, default: () => 0 },
    readonly: { type: Boolean },
  },
  data() {
    return {
      maxRating: 5,
    }
  },
}
</script>

<template>
  <div class="rating-container">
    <v-rating
      :value="value"
      v-bind="$attrs"
      v-on="$listeners"
      clearable
      :readonly="readonly"
      :length="maxRating"
      full-icon="mdi-heart"
      empty-icon="mdi-heart-outline"
      :color="readonly ? 'grey' : 'blue-grey'"
      :background-color="readonly ? 'grey' : 'blue-grey'"
      class="ml-0"
    />
  </div>
</template>

<style scoped>
.rating-container >>> .v-rating .v-icon {
  padding: 0 !important;
}
</style>
