<script>
import ItemRating from '@/features/items/components/ItemRating'
import { useDebouncedAutoSavedInputFieldRef } from '@/infrastructure/userInput/debounce'

export default {
  components: { ItemRating },

  props: {
    value: { type: Number },
    isEditMode: { type: Boolean, required: true },
    save: { type: Function, required: true },
  },

  setup(props) {
    const {
      val: newValue,
      isSaving,
      isShortlyAfterSaved,
    } = useDebouncedAutoSavedInputFieldRef({
      initialValue: props.value || 0,
      saveFunc: (val) => props.save({ newRating: val }),
      delay: 1,
    })

    return {
      newValue,
      isSaving,
      isShortlyAfterSaved,
    }
  },

  watch: {
    isEditMode(to) {
      if (to) {
        this.newValue = this.value
      }
    },
  },
}
</script>

<template>
  <div class="d-flex align-center">
    <ItemRating :value="newValue" :readonly="!isEditMode || isSaving" :size="!isEditMode ? 20 : 26" @input="newValue = $event" />

    <v-progress-circular v-if="isSaving" :width="2" size="13" color="grey" indeterminate class="ml-1" />
  </div>
</template>
