<script>
import { useDebouncedAutoSavedInputFieldRef } from '@/infrastructure/userInput/debounce'
import LabelAndSaveIndicator from '@/features/items/components/fields/LabelAndSaveIndicator'
import { toRefs } from '@vue/composition-api'
import { useItemDescriptionToLinkifiedHtml } from '@/features/items/composables/itemComposables'

export default {
  components: { LabelAndSaveIndicator },
  props: {
    value: { type: String },
    isEditMode: { type: Boolean, required: true },
    save: { type: Function, required: true },
  },

  setup(props) {
    const {
      val: newValue,
      isSaving,
      isShortlyAfterSaved,
    } = useDebouncedAutoSavedInputFieldRef({
      initialValue: props.value,
      saveFunc: (val) => props.save({ newDescription: val }),
    })

    const linkifiedValue = useItemDescriptionToLinkifiedHtml(toRefs(props).value)

    return {
      newValue,
      linkifiedValue,
      isSaving,
      isShortlyAfterSaved,
    }
  },

  watch: {
    isEditMode(to) {
      if (to) {
        this.newValue = this.value
      }
    },
  },
}
</script>

<template>
  <div v-if="!isEditMode" v-html="linkifiedValue"></div>

  <div v-else>
    <v-textarea v-model="newValue" rows="2" filled hide-details>
      <template v-slot:label>
        <LabelAndSaveIndicator label="Description" :is-saving="isSaving" :is-shortly-after-saved="isShortlyAfterSaved" />
      </template>
    </v-textarea>
  </div>
</template>
