<script>
import LabelAndSaveIndicator from '@/features/items/components/fields/LabelAndSaveIndicator'

let tmpLinkId = 0

export default {
  components: { LabelAndSaveIndicator },

  props: {
    value: { type: Array, required: true },
    disabled: { type: Boolean },
    isSaving: { type: Boolean, default: () => false },
    isShortlyAfterSaved: { type: Boolean, default: () => false },
  },

  data() {
    const initialLinkObjects = (this.value || []).map((x) => ({ tmpId: tmpLinkId++, text: x }))
    return {
      linkObjects: initialLinkObjects,
      previouslyEmittedValueAsString: this.convertEmittedValueToString(this.getValueToEmitFromLinkObjects(initialLinkObjects)),
    }
  },

  watch: {
    linkObjects: {
      handler(to) {
        const valueToEmit = this.getValueToEmitFromLinkObjects(to)
        const valueToEmitConvertedToString = this.convertEmittedValueToString(valueToEmit)

        if (valueToEmitConvertedToString !== this.previouslyEmittedValueAsString) {
          // this comparison is needed to avoid it firing 'input'/change events initially (which could trigger API save calls)
          this.previouslyEmittedValueAsString = valueToEmitConvertedToString
          this.$emit('input', valueToEmit)
        }
      },
      deep: true,
    },
  },

  created() {
    this.addEmptyIfAllNonEmpty()
  },

  methods: {
    getValueToEmitFromLinkObjects(linkObjects) {
      return linkObjects.filter((x) => (x.text || '').trim().length > 0).map((x) => x.text.trim())
    },
    convertEmittedValueToString(val) {
      return val.join()
    },

    addEmptyIfAllNonEmpty(skipIdx) {
      const isSkipLinkEmpty = skipIdx !== undefined && skipIdx < this.linkObjects.length - 1 && (this.linkObjects[skipIdx].text || '').trim().length === 0
      for (let i = this.linkObjects.length - 1; i >= 0; i--) {
        if (isSkipLinkEmpty && i === skipIdx) continue
        if (!isSkipLinkEmpty && i === this.linkObjects.length - 1) continue

        if ((this.linkObjects[i].text || '').trim().length === 0) {
          this.linkObjects.splice(i, 1)
        }
      }

      if (this.linkObjects.every((x) => (x.text || '').trim().length > 0)) {
        this.linkObjects.push({ tmpId: tmpLinkId++, text: '' })
      }
    },
  },
}
</script>

<template>
  <div>
    <transition-group name="fade">
      <v-text-field
        v-for="(linkObject, i) in linkObjects"
        :key="linkObject.tmpId"
        v-model="linkObject.text"
        @input="addEmptyIfAllNonEmpty(i)"
        :disabled="disabled"
        hide-details
        filled
        class="mb-3"
      >
        <template v-slot:label>
          <LabelAndSaveIndicator :label="i === 0 ? 'Url' : 'Another Url'" :is-saving="isSaving" :is-shortly-after-saved="isShortlyAfterSaved" />
        </template>
      </v-text-field>
    </transition-group>
  </div>
</template>
