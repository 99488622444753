<script>
import { useDebouncedAutoSavedInputFieldRef } from '@/infrastructure/userInput/debounce'
import LabelAndSaveIndicator from '@/features/items/components/fields/LabelAndSaveIndicator'

export default {
  components: { LabelAndSaveIndicator },
  props: {
    value: { type: String },
    isEditMode: { type: Boolean, required: true },
    save: { type: Function, required: true },
  },

  setup(props) {
    const {
      val: newValue,
      isSaving,
      isShortlyAfterSaved,
    } = useDebouncedAutoSavedInputFieldRef({
      initialValue: props.value,
      saveFunc: (val) => props.save({ newShop: val }),
    })

    return {
      newValue,
      isSaving,
      isShortlyAfterSaved,
    }
  },

  computed: {
    hasNonEmptyShopName() {
      return this.value && this.value.trim().length > 0
    },
  },

  watch: {
    isEditMode(to) {
      if (to) {
        this.newValue = this.value
      }
    },
  },
}
</script>

<template>
  <div v-if="!isEditMode" class="d-flex align-center flex-wrap">
    <v-icon small color="grey lighten-1" class="mr-1">mdi-cart-outline</v-icon>
    <span class="success--text item-shop-text">{{ hasNonEmptyShopName ? value : '[no shop]' }}</span>
  </div>

  <div v-else>
    <v-text-field v-model="newValue" filled hide-details>
      <template v-slot:label>
        <LabelAndSaveIndicator label="Shop name" :is-saving="isSaving" :is-shortly-after-saved="isShortlyAfterSaved" />
      </template>
    </v-text-field>
  </div>
</template>

<style scoped>
.item-shop-text {
  word-break: break-word;
}
</style>
