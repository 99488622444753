<script>
export default {
  props: {
    label: { type: String, required: true },
    isSaving: { type: Boolean, required: true },
    isShortlyAfterSaved: { type: Boolean, required: true },
  },
}
</script>

<template>
  <div class="d-flex align-center">
    <div>{{ label }}</div>

    <div v-if="isSaving" class="ml-2">
      <v-progress-circular :width="2" size="13" color="grey" indeterminate class="ml-1" />
    </div>
    <div v-else-if="isShortlyAfterSaved" class="ml-2">
      <v-icon size="13" color="green">mdi-check</v-icon>
    </div>
  </div>
</template>
