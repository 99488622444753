import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { useRouterManager } from '@/infrastructure/router/composable/routerComposables'
import debounce from '@/infrastructure/userInput/debounce'

export function useItemSearching({ items }) {
  const searchText = ref('')

  const itemsMatchingSearchQuery = computed(() => {
    return items.value.filter((item) => {
      if (searchText.value) {
        const searchableFields = [item.name, item.shop, item.description]
        if (!searchableFields.find((field) => field && String(field).toLowerCase().includes(searchText.value.toLowerCase()))) {
          return false
        }
      }

      return true
    })
  })

  return {
    searchText,
    itemsMatchingSearchQuery,
  }
}

export function useItemFiltering({ items, authUserIdRef }) {
  const selectedFilterOptions = ref([])

  const filteredItems = computed(() => {
    return items.value.filter((item) => {
      return selectedFilterOptions.value.every((f) => f.predicate({ item: item, authUserId: authUserIdRef.value }))
    })
  })

  return {
    selectedFilterOptions,
    filteredItems,
  }
}

export function useItemSorting({ items }) {
  const selectedSortOption = ref(null)

  const sortedItems = computed(() => {
    const tmpItems = items.value.map((x) => x)
    if (selectedSortOption.value) {
      tmpItems.sort(selectedSortOption.value.compareFunc)
    }
    return tmpItems
  })

  return {
    selectedSortOption,
    sortedItems,
  }
}

export function useQuerySyncForItemSearching({ searchTextRef }) {
  const { currentRoute, setQueryParam } = useRouterManager()

  const onUserUpdatedSearchText = debounce((newVal) => setQueryParam('search', newVal), 500)

  const initializeFromCurrentRoute = (newVal) => {
    if (newVal.query && newVal.query['search']) {
      searchTextRef.value = newVal.query['search']
    } else {
      searchTextRef.value = ''
    }
  }

  onMounted(() => initializeFromCurrentRoute(currentRoute.value))
  watch(currentRoute, initializeFromCurrentRoute)

  return {
    onUserUpdatedSearchText,
  }
}

export function useQuerySyncForItemFiltering({ selectedFilterOptionsRef, filterOptions }) {
  const { currentRoute, setQueryParam } = useRouterManager()

  const onUserUpdatedSelectedFilterOptions = (newVal) => setQueryParam('filter', newVal.map((x) => x.text).join(','))

  const initializeFromCurrentRoute = (newVal) => {
    if (newVal.query && newVal.query['filter']) {
      const filterQuery = newVal.query['filter']
      const splittedFilterTexts = (filterQuery || '').split(',')
      selectedFilterOptionsRef.value = filterOptions.filter((x) => splittedFilterTexts.includes(x.text)) || []
    } else {
      selectedFilterOptionsRef.value = []
    }
  }

  onMounted(() => initializeFromCurrentRoute(currentRoute.value))
  watch(currentRoute, initializeFromCurrentRoute)

  return {
    onUserUpdatedSelectedFilterOptions,
  }
}

export function useQuerySyncForItemSorting({ selectedSortOptionRef, sortOptions, defaultSortOption }) {
  const { currentRoute, setQueryParam } = useRouterManager()

  const onUserUpdatedSelectedSortOption = (newVal) => setQueryParam('sort', newVal === defaultSortOption ? undefined : newVal.queryParamKey)

  const initializeFromCurrentRoute = (newVal) => {
    if (newVal.query && newVal.query['sort']) {
      const sortQuery = newVal.query['sort']
      selectedSortOptionRef.value = sortOptions.find((x) => x.queryParamKey === sortQuery) || undefined
    } else {
      selectedSortOptionRef.value = defaultSortOption
    }
  }

  onMounted(() => initializeFromCurrentRoute(currentRoute.value))
  watch(currentRoute, initializeFromCurrentRoute)

  return {
    onUserUpdatedSelectedSortOption,
  }
}

function urlify(text) {
  const urlRegex = /(?:https?|chrome):\/\/[^\s$.?#<>].[^\s<>]*/gi
  return text.replace(urlRegex, function (url) {
    return `<a href="${url}" target="_blank">${url}</a>`
  })
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

function sanitizeHTML(text) {
  return text.replace(
    /[&<>'"]/g,
    (tag) =>
      ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;',
      }[tag] || tag)
  )
}

export function useItemDescriptionToLinkifiedHtml(descriptionRef) {
  return computed(() => urlify(sanitizeHTML(descriptionRef.value || '')).replace(/\n/g, '<br>'))
}
