<script>
import ItemDescriptionField from '@/features/items/components/fields/ItemDescriptionField'
import ItemLinksField from '@/features/items/components/fields/ItemLinksField'

export default {
  components: { ItemDescriptionField, ItemLinksField },

  props: {
    isEditMode: { type: Boolean, required: true },
    description: { type: String },
    links: { type: Array, required: true },
    saveDescription: { type: Function, required: true },
    saveLinks: { type: Function, required: true },
  },
}
</script>

<template>
  <v-card-text>
    <ItemDescriptionField :is-edit-mode="isEditMode" v-model="description" :save="saveDescription" />

    <div v-if="isEditMode" class="pt-8"></div>

    <ItemLinksField :is-edit-mode="isEditMode" v-model="links" :save="saveLinks" />
  </v-card-text>
</template>
